export enum SIZE_UNIT {
  IN_METERS = 1,
  IN_PYEONG = 2,
}

export const PROJECT_TYPE = {
  1: "아파트",
  2: "빌라",
  3: "오피스텔",
};
